.travelExpertCard {
    .expert {
        border-radius: 50%;
        width: 150px;
        height: 150px;
        margin-top: 0px;
        margin-left: auto;
        margin-right: auto;
    }
}
