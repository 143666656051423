@import '../../../styles/variables.scss';

.headerTop {
    display: flex;
    justify-content: center;
    height: 70px;
    background-color: $brand-primary-colour;
    z-index: 205;

    .wrapper {
        display: flex;
        flex-direction: row;
        height: 100%;
        width: 100%;
        max-width: 1160px;

        justify-content: stretch;
        align-items: center;

        @include respond-to('small') {
            margin-left: 20px;
            margin-right: 20px;
        }

        .home {
            height: 100%;
            width: 170px;
            background-image: url(../../../../public/images/logos/the-holiday-people-narrow.svg);
            text-indent: -1000%;
            background-size: 150px 50px;
            background-repeat: no-repeat;
            background-position-x: 10px;
            background-position-y: center;
            color: transparent;
            flex-grow: 0;
            margin-right: auto;

            @media (min-width: 640px) {
                width: 328px;
                background-image: url(../../../../public/images/logos/the-holiday-people-white.svg);
                background-size: 308px 50px;
            }

            @include respond-to('small') {
                width: 247px;
                background-position-x: 0;
                background-size: auto 40px;
            }
        }

        .abta {
            display: none;
            height: 100%;
            width: 80px;
            flex-grow: 0;
            justify-content: center;
            align-items: center;
            margin-left: 5px;
            margin-right: 5px;
            opacity: 0.9;

            picture {
                img {
                    height: 32px;
                }
            }

            @media (min-width: 480px) {
                display: flex;
            }
        }

        .atol {
            display: none;
            height: 100%;
            width: 80px;
            justify-content: center;
            align-items: center;
            margin-left: 5px;
            margin-right: 5px;
            opacity: 0.9;

            @media (min-width: 375px) {
                display: flex;
            }
        }

        .rightContent {
            flex-direction: column;
            align-items: flex-end;

            .callUs {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-grow: 0;
                margin-right: 5px;
                text-decoration: none;

                .icon {
                    fill: #fff;
                    height: 40px;
                    width: 40px;
                }

                .displayNumber {
                    display: none;
                    padding-left: 5px;
                    color: #fff;
                    outline: none;
                    font-size: 24px;
                }

                @include respond-to('small') {
                    height: initial;
                    margin-left: 15px;

                    .icon {
                        height: 28px;
                    }

                    .displayNumber {
                        display: initial;
                    }
                }
            }
        }

        .menuButton {
            flex-grow: 0;
            height: 100%;
            padding-right: 10px;
            padding-left: 10px;

            @include respond-to('small') {
                display: none;
            }
        }
    }

    @include respond-to('small') {
        height: 50px;
        border-bottom: none;
    }
}
