@import '../../../../styles/variables.scss';

.menuItem {
    height: 50px;
    border-bottom: 1px solid $brand-primary-colour;
    padding: 0;
    transition: background-color 0.1s linear;

    @include respond-to('small') {
        height: 40px;
        min-width: 150px;
    }

    > *:first-child {
        height: 100%;
        border: none;
        text-decoration: none;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0 20px;
        width: 100%;
        outline: none;
        font-size: 18px;
        background-color: transparent;

        @include respond-to('small') {
            font-size: 16px;
            padding: 0 10px;
            white-space: pre;
        }
    }

    &:hover {
        @include respond-to('small') {
            background-color: $brand-secondary-colour;
        }
    }
}
