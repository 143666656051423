@import '../../../styles/variables.scss';

.whyUsCard {
    border-radius: 15px;
    width: 100%;
    padding: 10px 20px 20px;
    background-color: $surface-colour;
    position: relative;
    text-align: center;
    box-shadow: 5px 5px 10px -5px rgba(0, 0, 0, 0.5);

    h3 {
        margin-top: 20px;
        text-align: center;
    }

    p {
        font-size: 14px;
        margin-left: auto;
        margin-right: auto;

        @media only screen and (min-width: 1200px) {
            width: 100%;
        }
    }

    .icon {
        position: absolute;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: $brand-primary-colour;
        top: -20px;
        left: calc(50% - 20px);

        svg {
            margin: 10px;
            width: 20px;
            height: 20px;
        }
    }
}
