@import '../../../../styles/variables.scss';

.mobileMenuButton {
    background: transparent;
    border: none;
    outline: none;

    .menuIcon {
        fill: #fff;
    }

    .closeIcon {
        fill: $brand-primary-colour;
    }

    &.isActive {
        background-color: $background-colour;
    }

    @include respond-to('small') {
        display: none;
    }
}
