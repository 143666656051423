@import '../../../styles/variables.scss';

.headerMenu {
    background: $background-colour;
    overflow-y: scroll;
    padding: 5px 10px 100px;
    height: calc(100vh - 68px);
    position: fixed;
    top: 70px;
    bottom: 0;
    width: 100%;
    z-index: 200;
    right: -100%;

    transition: right ease-in-out 0.2s;

    .wrapper {
        height: 100%;

        @include respond-to('small') {
            max-width: 1160px;
            width: calc(100% - 40px);
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }

        .menuItems {
            list-style: none;
            padding: 0;
            margin: 0;
            padding-bottom: 200px;
            user-select: none;

            .menuItem {
                @include respond-to('small') {
                    position: relative;
                    border-bottom: none;
                    height: 100%;
                    min-width: auto;
                }

                > *:first-child {
                    > svg {
                        @include respond-to('small') {
                            transform: rotate(0);
                            box-sizing: content-box;
                            height: 10px;
                            width: 10px;
                        }
                    }

                    @include respond-to('small') {
                        color: $body-text-colour;
                        font-size: 16px;
                        white-space: nowrap;
                        padding: 0 10px;
                    }
                }
            }

            @include respond-to('small') {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                align-items: center;
                flex-wrap: nowrap;
                width: 100%;
                padding-bottom: 0;
                height: 100%;
            }

            .homeLink {
                @include respond-to('small') {
                    display: none;
                }
            }
        }
    }

    &.isActive {
        right: 0;
    }

    @include respond-to('small') {
        display: flex;
        justify-content: center;
        position: unset;
        height: 36px;
        background-color: #fff;
        overflow-y: unset;
        padding: 0;
        border-bottom: 2px solid $brand-primary-dark-colour;
    }
}
