.socialIcon {
    display: inline-block;
    height: 40px;
    width: 40px;
    opacity: 0.7;
    margin-right: 5px;
    transition: opacity 0.2s linear;

    &:hover {
        opacity: 0.9;
    }

    &:last-child {
        margin-right: 0;
    }
}
