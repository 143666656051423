@import '../../../styles/variables.scss';

.builtForYouCard {
    a {
        color: $brand-primary-colour;
        text-decoration: underline;
    }

    .phoneNumber {
        font-size: 22px;
    }
}
