@import '../../styles/variables.scss';

.whyTheHolidayPeople {
    background-color: #fefef7;
    .wrapper {
        padding: 20px;
        max-width: 1200px;
        margin-left: auto;
        margin-right: auto;
        h2 {
            margin-bottom: 40px;
            text-align: center;
        }
        .board {
            display: flex;
            flex-flow: column nowrap;
            list-style: none;
            padding: 0;
            margin: 0;

            .card {
                margin-bottom: 40px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    @include respond-to('small') {
        .wrapper {
            .board {
                flex-flow: row wrap;
                align-items: stretch;
                justify-content: space-between;

                .card {
                    flex-basis: calc(50% - 10px);

                    &:last-child {
                        flex-basis: 100%;
                    }
                }
            }
        }
    }

    @include respond-to('medium') {
        .wrapper {
            .board {
                align-items: stretch;
                justify-content: space-between;

                .card {
                    flex-basis: calc((100% - 40px) / 3);
                    &:nth-child(4),
                    &:nth-child(5) {
                        flex-basis: calc((100% - 20px) / 2);
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    @include respond-to('large') {
        .wrapper {
            .board {
                align-items: stretch;
                justify-content: space-between;

                .card {
                    flex-basis: calc((100% - 40px) / 5);
                    margin-bottom: 0;
                    &:nth-child(4),
                    &:nth-child(5) {
                        flex-basis: calc((100% - 40px) / 5);
                    }
                }
            }
        }
    }
}
