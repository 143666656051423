.contactActions {
    .action {
        margin-bottom: 10px;
        height: 50px;
        font-size: 16px;
        padding-left: 5px;
        padding-right: 5px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}
