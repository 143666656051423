@import '../../styles/variables.scss';

.footer {
    background-color: #fff;
    text-align: center;
    border-top: 1px solid #444;

    .social {
        margin: 20px auto;
    }

    .address {
        font-size: 12px;
        padding: 0 20px;
        margin-bottom: 5px;
        li {
            display: inline-block;
            padding-right: 3px;
            &::after {
                content: ',';
            }

            &:last-child {
                padding-right: 0;
                &::after {
                    content: '';
                }
            }
        }
    }

    .links {
        margin: 0 auto 10px;
    }

    .copyright {
        margin: 0;
        padding: 3px 0;
        list-style: none;
        font-size: 9px;
        color: $body-text-light-colour;
    }

    @include respond-to('small') {
        .copyright {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-flow: row nowrap;
        }
    }
}
