@import '../../../styles/variables.scss';

.openingHours {
    background: $surface-colour;
    border-radius: 15px;
    padding: 10px 20px;
    box-shadow: 5px 5px 10px -5px rgb(0 0 0 / 50%);
    text-align: center;
    .openings {
        padding: 0;
        list-style: none;
        .opening {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            margin-bottom: 5px;

            .time {
                font-weight: bold;
            }
        }
    }
}
