@import '../../../../styles/variables.scss';

.button {
    width: 100%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $brand-primary-colour;
    text-decoration: none;
    font-size: 16px;
    height: 40px;
    padding: 0 20px;
    border-radius: 4px;
    background-color: #fff;
    color: $body-text-colour;
    transition: all 0.15s ease;
    -webkit-appearance: none;
    &:hover {
        background-color: #f9f9f9;
    }
}
