.links {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
        padding-bottom: 3px;
        a {
            font-size: 14px;
            text-decoration: underline;
        }
    }
}
