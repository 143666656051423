@import '../../styles/variables.scss';

.hero {
    height: 300px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: auto 300px;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;

    .headings {
        z-index: 2;
        width: 100%;
        padding: 20px;
        background-color: rgba(0, 0, 0, 0.3);
        h1,
        h2 {
            font-weight: normal;
            text-align: center;
            color: #fff;
        }

        h1 {
            font-size: 28px;
        }
        h2 {
            font-size: 20px;
        }

        &.hide {
            background-color: initial;
        }
    }

    @include respond-to('small') {
        height: 400px;
        background-size: auto 400px;

        .headings {
            h1 {
                font-size: 32px;
            }

            h2 {
                font-size: 26px;
            }
        }
    }
}
