@import '../../styles/variables.scss';

.header {
    height: $header-height-mobile;
    border-bottom: 1px solid #fff;

    @include respond-to('small') {
        height: $header-height;
        border-bottom: none;
    }
}
