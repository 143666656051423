@import '../../styles/variables.scss';

.contactOurTeam {
    .inner {
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: center;
        max-width: 1200px;
        padding: 0 20px;
        h2 {
            text-align: center;
            width: 100%;
        }

        p {
            text-align: center;
        }

        .actions {
            margin-bottom: 20px;
            width: 100%;
        }

        .hours {
            width: 100%;
        }
    }

    @include respond-to('small') {
        .actions,
        .hours {
            width: calc(50% - 10px);
            margin-bottom: 0;
            max-width: 350px;
        }

        .hours {
            margin-left: 20px;
        }
    }
}
