.flexibleCard {
    display: flex;
    flex-flow: column nowrap;
    ul {
        list-style: none;
        text-align: left;
        padding: 0;

        li {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 10px;

            svg {
                box-sizing: content-box;
                padding-right: 8px;
            }
        }
    }
}
